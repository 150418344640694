import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import { graphql } from 'gatsby'
import NewsletterPopup from 'components/newsletterPopup'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: null,
      firstLoaded: false,
    }
    this.setActiveIndex = this.setActiveIndex.bind(this)
  }

  componentDidMount() {
    this.setActiveIndex(0)
    this.setState({ firstLoaded: true })
  }

  setActiveIndex(index) {
    this.setState({ activeIndex: index })
  }

  render() {
    const { data } = this.props
    const locale = data.site.siteMetadata.locale
    // const translations = data.translations;
    // const tr = (code) => trFunction(code, translations, locale);
    let sections = data.landingSections.edges
    if (locale === 'zh') sections = sections.slice(0, 3)

    return (
      <Layout>
        <>
          <div
            id="landing"
            className={this.state.firstLoaded ? 'first-loaded' : ''}
          >
            {locale === 'zh' && (
              <div className="lan-wrap lan-zh">
                {sections.map((section, i) => (
                  <Section
                    key={i}
                    index={i}
                    section={section.node}
                    setActiveIndex={this.setActiveIndex}
                    activeIndex={this.state.activeIndex}
                  />
                ))}
              </div>
            )}
            {locale === 'en' && (
              <div className="lan-wrap lan-en">
                <div className="left">
                  {sections.map((section, i) => {
                    if (i < 1) {
                      return (
                        <Section
                          key={i}
                          index={i}
                          section={section.node}
                          setActiveIndex={this.setActiveIndex}
                          activeIndex={this.state.activeIndex}
                        />
                      )
                    }
                  })}
                </div>
                <div className="right">
                  {sections.map((section, i) => {
                    if (i >= 1) {
                      return (
                        <Section
                          key={i}
                          index={i}
                          section={section.node}
                          setActiveIndex={this.setActiveIndex}
                          activeIndex={this.state.activeIndex}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            )}
            <div className="menu-bottom-trigger" />
          </div>
          <NewsletterPopup data={data} />
        </>
      </Layout>
    )
  }
}
export default Home

const Section = ({ section, index, setActiveIndex, activeIndex }) => {
  let pageLink = '/'
  let slug = ''
  let activeClass = ''
  if (index === activeIndex) activeClass = 'active'
  if (section.page || section.link) {
    if (section.page) {
      slug = section.page.slug
      if (slug === 'collections-landing') pageLink = '/collections'
      if (slug === 'bespoke-landing') pageLink = '/bespoke'
      if (slug === 'collaborations') pageLink = '/collaborations'
    }
    return (
      <div
        className={`section ${activeClass} slug-${section.slug}`}
        onMouseEnter={() => {
          if (setActiveIndex) {
            setActiveIndex(index)
          }
        }}
      >
        {section.link && (
          <a href={section.link} target="_blank">
            <SectionInner section={section} />
          </a>
        )}
        {!section.link && (
          <LinkWrap to={pageLink}>
            <SectionInner section={section} />
          </LinkWrap>
        )}
      </div>
    )
  } else {
    return (
      <div
        className={`section no-link ${activeClass} slug-${section.slug}`}
        onMouseEnter={() => {
          if (setActiveIndex) {
            setActiveIndex(index)
          }
        }}
      >
        <SectionInner section={section} />
      </div>
    )
  }
}

const SectionInner = ({ section }) => {
  let image = section.image
  let imageM = image // default
  if (section.imageMobile) imageM = section.imageMobile

  return (
    <div className="inner">
      <div className="img-wrapper-proportion no-padding full-screen hide-for-mobile object-fit-holder">
        <ImageWrap image={image} />
      </div>
      <div className="img-wrapper-proportion no-padding full-screen show-for-mobile object-fit-holder">
        <ImageWrap image={imageM} />
      </div>

      <div className="text-wrap">
        <div className="excerpt-wrap">
          <p className="description">{section.description}</p>
        </div>
        <div className="title-wrap">
          <h1 className="title">{section.title}</h1>
          <img className="text-arrow" src="/images/text-after-arrow.png" />
        </div>
      </div>
    </div>
  )
}

export const landingQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    landingSections: allContentfulLandingSections(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          description
          image {
            ...GatsbyImage
          }
          imageMobile {
            ...GatsbyImage
          }
          page {
            slug
          }
          link
        }
      }
    }
  }
`
