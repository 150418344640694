import React from 'react'
import MailchimpForm from 'components/forms/mailchimp'
import LinkWrap from 'components/LinkWrap'
import { StaticImage } from 'gatsby-plugin-image'
import { trFunction } from 'utils/functions'

class NewsletterPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    if (!localStorage.getItem('suppressLogin')) {
      setTimeout(() => this.show(), 1000)
    }
  }

  show() {
    this.setState({ show: true })
  }

  hide() {
    this.setState({ show: false })
    localStorage.setItem('suppressLogin', true)
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    const showClass = this.state.show ? 'show' : ''

    return (
      <PopupEmail
        showClass={showClass}
        hide={this.hide}
        data={this.props.data}
        tr={tr}
      />
      // <PopupEvent1 showClass={showClass} hide={this.hide} tr={tr} />
      // <PopupEvent2 showClass={showClass} hide={this.hide} tr={tr} />
      // <PopupEvent3 showClass={showClass} hide={this.hide} tr={tr} />
    )
  }
}

export default NewsletterPopup

const PopupEmail = ({ showClass, hide, data, tr }) => {
  return (
    <div id="newsletter-popup" className={showClass}>
      <div className="overlay" onClick={hide} />
      <div className="popup">
        <div className="image">
          <StaticImage src="../assets/images/newsletter.png" alt="" />
        </div>
        <div className="close-x" onClick={hide}>
          <StaticImage src="../assets/images/newsletter-close.png" alt="" />
        </div>
        <div className="content">
          <div className="text">
            <h3>{tr('POPUP_LINE_1')}</h3>
            <h4 className="small-caps">{tr('POPUP_LINE_2')}</h4>
          </div>
          <div className="form-wrap">
            <MailchimpForm data={data} isPopup={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

const PopupEvent1 = ({ showClass, hide, tr }) => {
  return (
    <div id="newsletter-popup" className={`event event-1 ${showClass}`}>
      <div className="overlay" onClick={hide} />
      <div className="popup">
        <div className="image desktop">
          <StaticImage src="../assets/images/event-1-desktop.png" alt="" />
        </div>
        <div className="image mobile">
          <StaticImage src="../assets/images/event-1-mobile.png" alt="" />
        </div>
        <div className="content">
          <div className="text">
            <p>
              An immersive installation by Stellar Works and Sony for NYCxDESIGN
            </p>
            <p>10:00 - 18:00 on 18 - 25 May</p>
          </div>
          <div className="popup-button-wrap">
            <LinkWrap
              className="button"
              to="/news/stellar-works-and-sony-present-staydream-a-surreal-reality-for-nycxdesign"
            >
              READ MORE
            </LinkWrap>
          </div>
        </div>
      </div>
    </div>
  )
}

const PopupEvent2 = ({ showClass, hide, tr }) => {
  return (
    <div id="newsletter-popup" className={`event event-2 ${showClass}`}>
      <div className="overlay" onClick={hide} />
      <div className="popup">
        <div className="image">
          <StaticImage src="../assets/images/event-2.png" alt="" />
        </div>
        <div className="content">
          <div className="text">
            <p>
              An immersive installation by Stellar Works and Sony for NYCxDESIGN
            </p>
            <p>10:00 - 18:00 on 18 - 25 May</p>
          </div>
          <div className="popup-button-wrap">
            <LinkWrap
              className="button"
              to="/news/stellar-works-and-sony-present-staydream-a-surreal-reality-for-nycxdesign"
            >
              READ MORE
            </LinkWrap>
          </div>
        </div>
      </div>
    </div>
  )
}

const PopupEvent3 = ({ showClass, hide, tr }) => {
  return (
    <div id="newsletter-popup" className={`event event-3 ${showClass}`}>
      <div className="overlay" onClick={hide} />
      <div className="popup">
        <div className="image desktop">
          <StaticImage src="../assets/images/event-3.png" alt="" />
        </div>
        <div className="image mobile">
          <StaticImage src="../assets/images/event-3-mobile.png" alt="" />
        </div>

        <div className="content">
          <div className="text">
            <p>Stellar Works at Milan Design Week 2024</p>
            <p>10:00 - 21:00 on 15 - 21 April</p>
          </div>
          <div className="popup-button-wrap">
            <LinkWrap
              className="button"
              to="/news/stellar-works-at-2024-milan-design-week"
            >
              READ MORE
            </LinkWrap>
          </div>
        </div>
      </div>
    </div>
  )
}
